<template>
  <div>
    <input type="hidden" :value="token" id="token" name="token" />
    <iframe src="static/carinfomap/dashboard.html" frameborder="0"></iframe>
  </div>
</template>
<script>
export default {
    data() {
        return {
            token: ''
        }
    },
    created() {
        this.token = window.localStorage.getItem('jd_token')
    }
}
    
</script>
<style lang="less" scoped>
iframe {
  width: 100%;
  height: calc(100vh - 100px);
  overflow: hidden;
  margin: 0;
  
}
</style>